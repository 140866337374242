/* FormMgr */
import React, {Component} from "react";
import FormEnd from "./FormEnd.js";
//FormComp is the Component which is specific to form function eg:FormContact
//the file must be replaced with name of required component
import FormComp from "./FormContact";

const msgSuccess = "Thanks for your message, and have an awesome day!";

const msgFail = "Sorry, We are having technical problems, please try to Contact us later.";

// const msgDuplicate = "Your email is already subscribed to waynne.ca - no problem."

class FormMgr extends Component {
	constructor(props) {
		super(props);
		this.state = {
			manager:"",
			msg: ""
		};
	}
	componentDidMount() {
		this.setState({manager: "formik"});
	}

	submitCompleted = (stat) => {
		let x = 'end';
		let endMsg;
		console.log(stat);
		if (stat === 200) {
			endMsg = msgSuccess;
		} else {
			endMsg = msgFail;
		}
		this.setState({
			manager: x,
			msg: endMsg
		});
		//console.log("manager: " + this.state.manager);
	}


	render () {
		let select = "";
		if(this.state.manager === "formik") {
			select = <FormComp mgrCtl={this.submitCompleted} />;
		} else {
			select = <FormEnd endMsg={this.state.msg}/>;
		}
		return (
			<div>
				{select}
			</div>

		);
	}
}

export default FormMgr;