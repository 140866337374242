/* ResponsivePlayer */

import React, { Component } from "react";
import ReactPlayer from 'react-player';

class ResponsivePlayer extends Component {
	constructor(props) {
		super(props);
		//this.state = {}
		//onEnded: false

		this.onEnded = this.onEnded.bind(this);
	}

	componentDidMount() {
		//console.log('begin');
	}

	onEnded() {
		//console.log('testing onEnded');
	}
	render() {


		return (
			<div className='player-wrapper'>
				<ReactPlayer
					className='react-player'
					url={this.props.url}
					width='100%'
					height='100%'
					controls={true}
					onEnded={this.onEnded}
				/>
			</div>

		);
	}
}
export default ResponsivePlayer;