/* AlbumWaynneAndFriendsInit */
import React, {Component} from "react";
import Player from "./Player";
import dataIn from "./albumWaynneAndFriends.json";


class AlbumWaynneAndFriendsInit  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataIn
		};
	}
	componentDidMount() {
		/*dataIn is the album json file including tracks - done in this manner because of setstate issues with arrays
		*/
		this.setState ((state) => {
			return {
				dataIn
			};

		});
		//console.log(this.state);


	}
	render () {
		return (
			<div className="pdngTop-2em">
				<Player albumIn={this.state.dataIn} />
			</div>
		);
	}
}
export default AlbumWaynneAndFriendsInit;