/* Contact */
import React, {Component} from "react";
import  Row from "react-bootstrap/Row";
import  Col from "react-bootstrap/Col";
import FormMgr from "./FormMgr";

import {Title, CentrdText} from "./theme.js";

const alignText = {
	paddingTop: "30px",
	paddingBottom: "30px"
};

class Contact extends Component {

	render() {

		return (
			<div className="navbar-spacer">
				<Row>
					<Col xs={12}>
						<Title>
                                CONTACT
						</Title>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={{span:6, offset:3}} lg={{span:6, offset:3}}>
						<div style={alignText}>
							<CentrdText>
								Send Waynne a message:
							</CentrdText>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={{span:6, offset:3}} lg={{span:4, offset:4}}>
						<FormMgr />
					</Col>
				</Row>
			</div>

		);
	}
}

export default Contact;