/* FormContact.js */
import React, {Component} from 'react';
import { Formik } from 'formik';
import {Input, Text, Button, Label, TextArea, ContactForm} from './theme.js';
import * as Yup from 'yup';
//import "./Home.css";


const formSchema = Yup.object().shape({
	email: Yup.string()
		.email('Invalid email')
		.required('Required'),
});


export class FormContact extends Component {
	//constructor(props) {
	//  super(props);
	// this.submit.Completed = this.submitted.Completed.bind.(this);
//}

  handleSubmit = (values, {
  	props = this.props,
  	setStatus,
  	resetForm,
  	setSubmitting
  }) => {
  //	console.log(JSON.stringify(values));
  	function handleErrors(response) {
  		if (!response.ok) {
  			//throw Error(response.statusText);
  			console.log(response.send);
  			console.log (response.status);
  		}
  		return response;
  	}
  	// 'host' is set by react start/build

	  const host = (process.env.REACT_APP_EXPRESS_URL);

	 // host = the http(s) + the host + the port(localhost only (dev))

  	const expressUrl = host + '/routes/newContact';

  	console.log(expressUrl);
  	fetch(expressUrl, {
  		method: 'POST',
  		mode: 'cors',
  		body: JSON.stringify(values),
  		redirect: 'follow',
  		headers: new Headers({
  			'Content-Type': 'application/json'
  		})
	  })
	  .then(handleErrors)
  		.then((res) => {
			  let stat = res.status;
  			console.log(stat);
  			//if (res.status === 200) {
  				this.props.mgrCtl(stat);
  			//}
  		})
  		.catch(error => console.log(error));

  	// used to prevent multiple reentry of form ??? - setSubmitting(false);

  };

  render() {

  	return (
  		<div>
  			{/* FORMIK */}
  			<Formik
  				initialValues={{
					  email: '',
					  name: '',
  					message: ''
  				}}
  				validationSchema={formSchema}

  				onSubmit={this.handleSubmit}

  				render={({
  					touched,
  					errors,
  					status,
  					values,
  					handleChange,
  					handleBlur,
  					handleSubmit,
  				}) => (
  					<ContactForm onSubmit = {handleSubmit}>
						  <div>
  						<Label>

  							{touched.email &&
               errors.email && <Text color="red">{errors.email}</Text>}
  							<Input
  								onChange={handleChange}
  								onBlur={handleBlur}
  								value={values.email}
  								border={touched.email && errors.email && "1px solid red"}
  								type="text"
  								name="email"
  								placeholder="Enter Your Email Address"
  							/>
  						</Label>
						  <Label>
						  <Input
  								onChange={handleChange}
  								onBlur={handleBlur}
  								value={values.name}
  								border={touched.name && errors.name && "1px solid red"}
  								type="text"
  								name="name"
  								placeholder="Enter Your Name"
  							/>
						  </Label>
  							<TextArea className="mrgnBtm"
  								onChange={handleChange}
  								onBlur={handleBlur}
  								value={values.message}
  								border={touched.message && errors.message && "1px solid red"}  	type="textarea"
  								name="message"
  								placeholder="Enter Your Message"
  							/>

  						<Button
  							type="submit">SUBMIT</Button>
							  </div>
  					</ContactForm>
  				)}
  			/>
  		</div>
  	);
  }
}
export default FormContact;