/* AlbumFreeForAllInit */
import React, {Component} from "react";
import Player from "./Player";
import dataIn from "./albumFreeForAll.json";
//Note: datain has been replaced with sample tracks for testing purposes
//import dataIn from "./albumSample.json";


class AlbumFreeForAllInit  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataIn
		};
	}
	componentDidMount() {
		/*dataIn is the album json file including tracks - done in this manner because of setstate issues with arrays
		*/
		this.setState ((state) => {
			return {
				dataIn
			};

		});
		//console.log(this.state);


	}
	render () {
		return (
			<div>
				<Player albumIn={this.state.dataIn} />
			</div>
		);
	}
}
export default AlbumFreeForAllInit;