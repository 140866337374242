/* Videos */
import React, { Component } from "react";
import FormMgrElist from "./FormMgrElist";
import ResponsivePlayer from "./ResponsivePlayer";
import { Title } from "./theme.js";
import './index.css';




class Videos extends Component {
	render() {

		return (
			<div className="navbar-spacer">
				<div>
					<Title>
						VIDEOS
					</Title>
				</div>
				<div className="vidContainer">
					<ResponsivePlayer
						url={'https://www.youtube.com/watch?v=g4SgRdzc3Vg'}
					/>
				</div>
				<div className="vidContainer">
					<ResponsivePlayer
						url={'https://www.youtube.com/watch?v=UIS5TSAdlT0'}
					/>
				</div>

				<div>
					<FormMgrElist formEndMsg={"Thanks for subscribing!"} />
				</div>
			</div>

		);
	}
}

export default Videos;