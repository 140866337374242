/* Home.js */
import React, { Component } from "react";
//import Row from "react-bootstrap/Row";
//import Col from "react-bootstrap/Col";
import { Redirect } from 'react-router-dom';
import "./Home.css";
import HomePlayerInit from "./HomePlayerInit";
import FormMgrElist from "./FormMgrElist";
import ResponsivePlayer from "./ResponsivePlayer";
import waynneplaying from "./images/waynne-home.jpg";

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displaySeq: 0,
			tokenIn: '',
			redirToEmVerify: false
		};
	}

	componentDidMount() {
		//let redirToEmVerify = false;

		// get the url params redirect to EmVerify if valid token

		const tokenIn = this.props.location.search;
		if (tokenIn.startsWith('?token=')) {
			console.log('token valid');
			console.log(tokenIn);
			this.setState({
				token: tokenIn,
				redirToEmVerify: true
			});
		}


	}

	render() {

		return (
			<div className="navbar-spacer">
				<div>
					{this.state.redirToEmVerify && (<Redirect to={{
						pathname: "/EmVerify",
						state: { token: this.state.token }
					}} />)}
				</div>
				<div>
					<div className="home-main">
						<div className="mainImg">
							<img
								className="responsive"
								src={waynneplaying}
								alt="Waynne on beach"
							/>
						</div>
						<div>
							<HomePlayerInit />
						</div>
					</div>

					<div>
						<FormMgrElist />
					</div>
					<div className="vidContainer">
						<ResponsivePlayer
							url={'https://www.youtube.com/watch?v=g4SgRdzc3Vg'}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;
