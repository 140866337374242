// Controls
import React from "react";

class Controls extends React.Component {
	//constructor(props) {
	//	super(props);
	// eslint-disable-next-line no-console
	//console.log(this.props);

	//this.renderListItem = this.renderListItem.bind(this);
	//}
	render() {

		return (
			<div>
				<div className="Controls">

					{!this.props.playing &&
						<i
							id="play"
							onClick={this.props.onClick}
							className="fa fa-fw fa-play iconPlay"
						/>}
					{this.props.playing &&
						<i
							id="pause"
							onClick={this.props.onClick}
							className="fa fa-fw fa-pause iconPlay"
						/>}
					<p className="dsply-track">{this.props.title}</p>
					<i
						id="prev"
						className="fa fa-fw fa-fast-backward iconFastBackward"
						onClick={this.props.onClick}
					/>
					<i
						id="next"
						className="fa fa-fw fa-fast-forward iconFastForward"
						onClick={this.props.onClick}
					/>
				</div>
				<div className="progressContainer">
					<div className="sliderContainer">
						<div className="progress" style={{ width: this.props.progress }}></div>
					</div>
					<div className="dsplyContainer">
						<div className="dsply-currentTime">{this.props.currentTime}</div>
						<div className="dsply-span">/</div>
						<div className="dsply-duration">{this.props.duration}</div>
					</div>

				</div>
				<div className="controlsBottom"></div>

			</div>
		);
	}
}
export default Controls;