
/* FormEnd */
import React, {Component} from "react";
import "./forms.css";

class FormEnd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			manager:""
		};
	}

	render () {

		return (
			<div className="msgContainer pdngTop-2em pdngBtm-2em">
				<div className="message">
					<div className="fa fa-check  fa-2x icon">
					</div>
					<div className="formEndStyl">
						<p>{this.props.endMsg}</p>
					</div>
				</div>
			</div>

		);
	}
}

export default FormEnd;