/* playerAlbumInfo */

import React, {Component} from "react";
import "./player.css";


class playerAlbumInfo extends Component {

	render() {

		return (
			<div className="albumContainer">
				<div className="albumArt">
					<img src={process.env.PUBLIC_URL + this.props.artwork} alt="Waynne" ></img>
				</div>
				<div>
					<p className="albumTitle">{this.props.title}</p>
				</div>
				<div>
					<p className="albumAuthor">{this.props.artist}</p>
				</div>
			</div>
		);
	}
}

export default playerAlbumInfo;