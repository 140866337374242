import React, { Component } from "react";
import "./App.css";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {LinkContainer, IndexLinkContainer } from "react-router-bootstrap";
import Home from "./Home";
import Music from "./Music";
import Contact from "./Contact";
import Videos from "./Videos";
import About from "./About.js";
import EmVerify from "./EmVerify";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import  Navbar from "react-bootstrap/Navbar";


class App extends Component {
	/*constructor(props) {
     */
	render() {

		return (
			<Container fluid={true} className="container-fluid">
				<Router>
					<div>
						<Navbar collapseOnSelect fixed="top" expand="lg" className="bkgnd-navbar navlink">

							<Navbar.Brand href="#home">
								<img
									src="/logo192.png"
									width="50"
									height="50"
									className="d-inline-block align-top"
									alt="logo"
								/>
							</Navbar.Brand>

							<Navbar.Brand className="brand" href="#home">waynne.ca</Navbar.Brand>
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="justify-content-center mr-auto">
									<IndexLinkContainer to="/">
										<Nav.Link className="navLink" href="#">HOME</Nav.Link>
									</IndexLinkContainer>
									<LinkContainer className="navLink" to="/Music">
										<Nav.Link href="#">MUSIC</Nav.Link>
									</LinkContainer>
									<LinkContainer to="/Videos">
										<Nav.Link className="navLink" href="#">VIDEOS</Nav.Link>
									</LinkContainer>
									<LinkContainer to="/Contact">
										<Nav.Link className="navLink" href="#">CONTACT</Nav.Link>
									</LinkContainer>
									<LinkContainer to="/About">
										<Nav.Link className="navLink" href="#">ABOUT</Nav.Link>
									</LinkContainer>
									<LinkContainer to="/EmVerify" style={{display:'none'}}>
										<Nav.Link className="navLink" href="#">EMAIL VERIFICATION</Nav.Link>
									</LinkContainer>
								</Nav>
							</Navbar.Collapse>
						</Navbar>
					</div>
					<div>
						<Switch>
							<Route exact path="/" component={Home}/>
							<Route exact path="/Music" component={Music}/>
							<Route exact path="/Contact" component={Contact}/>
							<Route exact path="/Videos" component={Videos}/>
							<Route exact path="/About" component={About}/>
							<Route exact path="/EmVerify" component={EmVerify}/>

						</Switch>
					</div>
				</Router>
			</Container>
		);
	}
}

export default App;
