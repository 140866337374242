/* HomePlayerInit */
import React, {Component} from "react";
import Player from "./Player";
import dataIn from "./albumHome.json";
//dataIn is the json file containing album info


class HomePlayerInit  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataIn
		};
	}
	componentDidMount() {
		/*dataIn is the album json file including tracks - done in this manner because of setstate issues with arrays
		*/
		this.setState ((state) => {
			return {
				dataIn
			};

		});
		//console.log(this.state);


	}
	render () {
		return (
			<div>
				<Player
					albumIn={this.state.dataIn}
					home={true}
				/>
			</div>
		);
	}
}
export default HomePlayerInit;