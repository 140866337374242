// TrackList

import React from "react";

class TrackList extends React.Component {
	constructor(props) {
		super(props);
		// eslint-disable-next-line no-console
		//console.log(this.props);
		this.state = {
			tracks: [],
			autoPlay: false
		};
		this.renderListItem = this.renderListItem.bind(this);
	}
	componentDidUpdate() {
		if (this.activeTrack) {
			let topOfTrackList = this.trackList.scrollTop;
			let bottomOfTrackList =
        this.trackList.scrollTop + this.trackList.clientHeight;
			let positionOfSelected = this.activeTrack.offsetTop;
			if (
				topOfTrackList > positionOfSelected ||
        bottomOfTrackList < positionOfSelected
			) {
				this.trackList.scrollTop = positionOfSelected;
			}
		}
	}
	componentDidMount() {

		//props for tracks from HomePlayer are props.data
		this.setState({ tracks: this.props.data }, function () {
			//console.log(this.state.tracks);
		});
	}

	renderListItem(track, i) {

		let trackClass = this.props.currentTrackIndex === track.id
			? "selected"
			: "";
		return (
			<li
				key={track.id}
				className={trackClass}
				ref={cur => {
					if (this.props.currentTrackIndex === track.id) {
						this.activeTrack = cur;
					}
				}}
				onClick={()=>{
					this.props.selectTrackNumber(track.id);
				}}
			>
				<div className="number">{track.id}</div>
				<div className="title">{track.title}</div>
			</li>
		);
	}
	render() {

		let tracks = this.state.tracks.map(this.renderListItem);

		return (
			<ul
				className="TrackList"
				ref={input => {
					this.trackList = input;
				}}
			>
				{tracks}
			</ul>
		);
	}
}
export default TrackList;