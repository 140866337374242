import React, { Component } from "react";
import Controls from "./Controls";
import TrackList from "./TrackList";
import PlayerAlbumInfo from "./PlayerAlbumInfo";
import PlayerHomeFeatured from "./PlayerHomeFeatured";


import "./player.css";
// Data
//import data from "./playerTracks.json";

class Player extends Component {
	constructor(props) {
		super(props);
		this.state = {
			playing: false,
			currentTrackIndex: 0,
			currentTrackName: " ",
			currentTrackDuration: "",
			currentTrackDurSecs: 0,
			currentTrackTime: 0,
			progress: 0,
			tempTime: 0,
			nextSec: 1,
			tracksIn: [],
			albumData: {}
		};

		//console.log(this.props);
		this.handleClick = this.handleClick.bind(this);
		this.playAudio = this.playAudio.bind(this);
		this.pauseAudio = this.pauseAudio.bind(this);
		this.continueAudio = this.continueAudio.bind(this);
		this.selectTrackNumber = this.selectTrackNumber.bind(this);
		this.updateTime = this.updateTime.bind(this);
		this.evlEnded = this.evlEnded.bind(this);
		this.evlMetadata = this.evlMetadata.bind(this);

	}

	updateTime() {
		//console.log('entered function updateTime');
		if (this.state.tempTime > this.state.nextSec) {
			let curMins = this.state.tempTime / 60;
			curMins = (Math.trunc(curMins));
			//console.log(curMins);
			let xcursecs = this.state.tempTime - (curMins * 60);
			//console.log(curSeconds);
			function pad(value, length) {
				return (value.toString().length < length) ? pad("0" + value, length) : value;
			}
			var curSeconds = pad(xcursecs, 2);
			let currTime = (curMins + ":" + curSeconds);
			//console.log(tempTime);
			//console.log(this.state.currentTrackDuration);

			let calcProgress = (this.state.tempTime / this.state.currentTrackDurSecs) * 100;
			let progressPercent = (Math.trunc(calcProgress));
			let progress = (progressPercent + '%');
			//console.log(progress);

			this.setState({
				currentTrackTime: currTime,
				progress: progress,
				nextSec: this.state.nextSec + 1
			});

			//this.audioElement.removeEventListener('timeupdate', this.updateTime);

		//nextSec = nextSec + 1;
		//console.log(nextSec);
		} else {
		//console.log('enter else');
			let x = this.audioElement.currentTime;
			this.setState({ tempTime: (Math.trunc(x)) });
		}

	}

	evlEnded() {
		//console.log('ended');

		setTimeout(() => {

			this.setState((state, props) => {

				//console.log('entered autoplay');
				let currentTrackIndex = state.currentTrackIndex + 1;
				if (currentTrackIndex > this.props.albumIn.tracks.length) {
				//console.log('entered autoplayreturn null');
					return { playing: false };
				} else {
				//console.log('entered autoplay return setstate');
					return {
						playing: true,
						currentTrackIndex: currentTrackIndex,
						currentTrackName: this.props.albumIn.tracks[currentTrackIndex - 1].title,
						tempTime: 0,
						nextSec: 1
					};
				}
			}, this.playAudio);
		//console.log('done');
		}, 2000);
	}

	evlMetadata() {
		//console.log('entered loadedmetadata');
		var y = this.audioElement.duration;
		let ttlSeconds = (Math.trunc(y));
		//console.log(ttlSeconds);
		let xmins = ttlSeconds / 60;
		let mins = (Math.trunc(xmins));
		//console.log(mins);

		let xsecs = ttlSeconds - (mins * 60);
		//console.log(seconds);
		function pad(value, length) {
			return (value.toString().length < length) ? pad("0" + value, length) : value;
		}
		var seconds = pad(xsecs, 2);
		//console.log(seconds);
		let duration = (mins + ":" + seconds);
		//console.log('duration: ' + duration);
		this.setState({
			currentTrackDuration: duration,
			currentTrackDurSecs: ttlSeconds
		});
	}

	componentDidMount() {
		this.audioElement.addEventListener('ended', this.evlEnded);

		let currentTrackIndex = 1;
		this.setState({
			playing: false,
			currentTrackIndex: 1,
			currentTrackName: this.props.albumIn.tracks[currentTrackIndex - 1].title,
			currentTrackTime: " ",
			progress: 0,
			tempTime: 0,
			nextSec: 1
		});

		this.audioElement.addEventListener('ended', this.evlEnded);

		this.audioElement.addEventListener('loadedmetadata', this.evlMetadata);

		this.audioElement.addEventListener('timeupdate', this.updateTime);
	}

	playAudio() {
		//console.log('begin playaudio');
		if (!this.state.playing) {
			//console.log('returns when end of tracklist reached');
		} else {

			this.audioElement.load();

			this.audioElement.play();
		}
	}

	pauseAudio() {
		this.audioElement.pause();
	}
	continueAudio() {
		this.audioElement.play();
	}
	selectTrackNumber(trackId) {
		this.setState({
			currentTrackIndex: trackId,
			currentTrackName: this.props.albumIn.tracks[trackId - 1].title,
			playing: true,
			progress: 0,
			tempTime: 0,
			nextSec: 1
		}, this.playAudio);
		//console.log(this.state);
	}
	handleClick(e) {
		switch (e.target.id) {
		case "play":
			this.setState((state, props) => {
				let currentTrackIndex = state.currentTrackIndex;
				if (currentTrackIndex === 0) {
					currentTrackIndex = 1;
				}

				return {
					playing: true,
					currentTrackIndex: currentTrackIndex,
					currentTrackName: this.props.albumIn.tracks[currentTrackIndex - 1].title
				};
			}, this.continueAudio);
			break;
		case "pause":
			//console.log(this.state.tempTime);
			this.setState({ playing: false }, this.pauseAudio);
			break;
		case "prev":
			this.setState((state, props) => {
				let currentTrackIndex = state.currentTrackIndex - 1;
				if (currentTrackIndex <= 0) {
					return null;
				} else {
					return {
						playing: true,
						currentTrackIndex: currentTrackIndex,
						currentTrackName: this.props.albumIn.tracks[currentTrackIndex - 1].title,
						progress: 0,
						tempTime: 0,
						nextSec: 1
					};
				}
			}, this.playAudio);
			break;
		case "next":
			this.setState((state, props) => {
				let currentTrackIndex = state.currentTrackIndex + 1;
				if (currentTrackIndex > this.props.albumIn.tracks.length) {
					return null;
				} else {
					return {
						playing: true,
						currentTrackIndex: currentTrackIndex,
						currentTrackName: this.props.albumIn.tracks[currentTrackIndex - 1].title,
						progress: 0,
						tempTime: 0,
						nextSec: 1
					};
				}
			}, this.playAudio);
			break;
		default:
			break;
		}
	}

	componentWillUnmount() {
		//console.log("we have arrived at the ccomponentwillunmount!");
		//this.audioElement.removeEventListener('ended', () => this.setState({ playing: false }));

		this.audioElement.removeEventListener('loadedmetadata', this.evlMetadata);

		this.audioElement.removeEventListener('timeupdate', this.updateTime);

		this.audioElement.removeEventListener('ended', this.evlEnded);

		//this.audioElement.removeEventListener('timeupdate', () => ({}));
	}

	render() {
		let playerClass = "player";
		if (this.props.home) {
			playerClass = "homePlayer";
		}
		//console.log(this.props);
		return (
			<div className={playerClass}>
				<div>
					{this.props.home
						? <PlayerHomeFeatured />
						: <PlayerAlbumInfo
							title={this.props.albumIn.title}
							artist={this.props.albumIn.artist}
							artwork={this.props.albumIn.artwork}
						/>
					}
				</div>

				<div>
					<Controls onClick={this.handleClick}
						playing={this.state.playing}
						title={this.state.currentTrackName}
						duration={this.state.currentTrackDuration}
						progress={this.state.progress}
						currentTime={this.state.currentTrackTime} />
					<audio ref={(audio) => {
						this.audioElement = audio;
					}}
					src={"/songs/" + this.state.currentTrackName + ".mp3"} />
				</div>
				<div>
					<TrackList
						data={this.props.albumIn.tracks}
						currentTrackIndex={this.state.currentTrackIndex}
						selectTrackNumber={this.selectTrackNumber}
					/>
				</div>
				<div className="MusicCredit">
					<p>Copyright © 2019 Waynne Penney. All rights reserved.</p>
				</div>
				<div className="clearfix"></div>

			</div>
		);
	}
}

export default Player;