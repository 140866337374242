import React, {Component} from "react";
import FormMgrElist from "./FormMgrElist";
import {Title} from "./theme.js";
import AlbumFreeForAllInit from "./AlbumFreeForAllInit.js";
import AlbumMoniqueInit from "./AlbumMoniqueInit.js";
import AlbumWaynneAndFriendsInit from "./AlbumWaynneAndFriendsInit";


class Music extends Component {

	render() {

		return (
			<div className="navbar-spacer">

				<div>
					<Title>
                                MUSIC
					</Title>
				</div>
				<div>
					<AlbumFreeForAllInit />
				</div>
				<div>
					<AlbumMoniqueInit />
				</div>
				<div>
					<AlbumWaynneAndFriendsInit />
				</div>
				<div>
					<FormMgrElist formEndMsg={"Thanks for subscribing!"} />

				</div>
			</div>

		);
	}
}

export default Music;