/* FormMgrElist */
import React, {Component} from "react";
import FormEnd from "./FormEnd.js";
//FormComp is the Component which is specific to form function eg:FormContact
//the file must be replaced with name of required component
import FormComp from "./FormElist.js";

const msgSuccess = "Thanks for subscribing to waynne.ca! We have sent you a confirmation email.";

const msgDuplicate = "You are already subscribed - Have a great day:)";

const msgFail = "Sorry, we had a technical problem - Please try again later";

class FormMgrElist extends Component {
	constructor(props) {
		super(props);
		this.state = {
			manager:"",
			msg: ""
		};
	}
	componentDidMount() {
		this.setState({manager: "formik"});
	}

	submitCompleted = (stat) => {
		let x = 'end';
		let endMsg;
		//console.log(stat);

		switch(stat) {

		case '200':
			endMsg = msgSuccess;
			break;
		case '300':
			endMsg = msgDuplicate;
			break;
		default:
			console.log('unhandled');
			endMsg = msgFail;
			//throw new Error(msgFail);
		}

		this.setState({
			manager: x,
			msg: endMsg
		});
	}


	render () {
		let select = "";
		if(this.state.manager === "formik") {
			select = <FormComp mgrCtl={this.submitCompleted} />;
		} else {
			//select = <FormEnd endMsg={this.props.formEndMsg}/>;
			select = <FormEnd endMsg={this.state.msg}/>;
		}
		return (
			<div>
				{select}
			</div>
		);
	}
}

export default FormMgrElist;