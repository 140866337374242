/* FormElist.js */
import React, {Component} from 'react';
import { Formik } from 'formik';
import {CentrdText, Form, Input, Text, Button, Label} from './theme.js';
import * as Yup from 'yup';
//import "./Home.css";

const formSchema = Yup.object().shape({
	email: Yup.string()
		.email('Invalid email')
		.required('Required'),
});

const txtErr = {
	display:"block",
	fontSize: "1em"
};

export class FormElist extends Component {
//constructor(props) {
//  super(props);
// this.submit.Completed = this.submitted.Completed.bind.(this);
//}

handleSubmit = (values, {
	props = this.props,
	setStatus,
	resetForm,
	setSubmitting
}) => {

	//console.log(JSON.stringify(values));
	// send to route newSubscriber


	// 'host' is set by react start/build

	const host = (process.env.REACT_APP_EXPRESS_URL);

	// host = the http(s) + the host + the port(localhost only (dev))

	const expressUrl = host + '/routes/newSubscriberNoVrf';

	//console.log(expressUrl);
	fetch(expressUrl, {
		method: 'POST',
		//mode: 'no-cors',
		body: JSON.stringify(values),
		redirect: 'follow',
		headers: new Headers({
			'Content-Type': 'application/json'
		})
	})

		.then(res => {
			res.json().then(data => {

				//console.log(res.status);
				//console.log(data.error);
				if (data.error === "1062") {
					let stat = "300";
					//return to FormMgrElist
					this.props.mgrCtl(stat);
				} else {
					// 'host' is set by react start/build

					const host = (process.env.REACT_APP_EXPRESS_URL);

					// host = the http(s) + the host + the port(localhost only (dev))

					const expressUrl = host + '/routes/emailSbscrbrNoVrfy';

					console.log(expressUrl);
					fetch(expressUrl, {
						method: 'POST',
						//mode: 'no-cors',
						body: JSON.stringify(values),
						redirect: 'follow',
						headers: new Headers({
							'Content-Type': 'application/json'
						})
					})
						.then(res => {
							res.json().then(data => {
								let stat;
								//console.log(res.status);
								//console.log(data);
								if (res.status < 201) {
									stat = '200';
								} else {
									stat = '500';
								}
								//return to FormMgrElist
								this.props.mgrCtl(stat);

							});
						});
				}
			});
		});
}

render() {

	return (
		<div>
			<CentrdText>
Join my mailing list for the latest news!
			</CentrdText>
			{/* FORMIK */}
			<Formik
				initialValues={{
					email: '',
					message: ''
				}}
				validationSchema={formSchema}

				onSubmit={this.handleSubmit}

				render={({
					touched,
					errors,
					status,
					values,
					handleChange,
					handleBlur,
					handleSubmit,
				}) => (
					<Form onSubmit = {handleSubmit}>
						<Label>
							{touched.email &&
errors.email && <Text style={txtErr} color="red">{errors.email}
							</Text>}
							<Input
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.email}
								border={touched.email && errors.email && "1px solid red"}
								type="text"
								name="email"
								placeholder="Enter Your Email Address"
							/>
							<Button
								type="submit">SUBSCRIBE</Button>
						</Label>

					</Form>
				)}
			/>
		</div>
	);
}
}
export default FormElist;