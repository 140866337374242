import styled from "styled-components";

// Forms, inputs, buttons
/*@media(min-width: 600px) {
  flex-direction: row;
  align-self: center;
  justify-content: center;
} */

export const Form = styled.form`
  display: flex;
  margin: 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
`;

export const Label = styled.label`
  color:white;
  font-family: "Raleway", sans-serif;
  font-size: 1em;
  margin: 0.5em 0;
 /* position: relative; */ 
`;

export const Input = styled.input`
  height: 50px;
  padding: 10px;
   border: ${props => props.border || "1px solid #ccc"};
   border-radius: 10px;
  background-color: #fff;
  font-size: 1.3em;
  @media only screen and (max-width: 600px) {
    width: 300px;
  }
  @media only screen and (min-width: 600px) {
    width: 500px;
  }
`;

export const ContactForm = styled(Form)`
align-items: start;
`;
export const Button = styled.button`
  display: block;
  width: 80%;
  height: 50px;
  margin-top: 20px;
  font-size: 1.3em;
  background-color: #0191c8;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  @media only screen and (max-width: 600px) {
    width: 300px;
  }
  @media only screen and (min-width: 600px) {
    width: 500px;
  }
`;

export const Success = styled.p`  
 font-family: "Gochi Hand";
  color: #fff;
  font-weight: 300;
  font-size: 1.2em;
`;

// Text

export const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  /* color: 	#708090; */
  color: purple;
  font-size: 1.5em;
  width: 100%;
  text-align: center;
  /*padding 3em; */
  padding-top: 20px;
  padding-bottom: 20px;
  @media(min-width: 770px) {
    display: none;
  }
`;

export const Title2 = styled.h2`
font-family: 'Gruppo', cursive;
font-weight: 300;
  color: #4d4d4d;
  font-size: 1.4em;
  text-align: center;
  padding-bottom: 1em;
`;

export const Text = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: 1.25em;
  font-weight: 500;
  padding-bottom: 10px;
  padding-left: 5px;
  color: ${props => props.color || "#4d4d4d"}
`;
export const CentrdText = styled.p`
font-family: 'Lato', cursive;
font-weight: 500;
  font-size: 2em;
  color:#03256C;
  width:100%;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;

`;
export const TextArea = styled.textarea`
width:90%;
height:10em;
padding: .5em;
border-radius: 10px;
font-size: 1.3em;
font-family: 'Lato', cursive;
resize: none;
@media(min-width: 750px) {
  width: 500px;
  align-self: center;
}
`;
export const Msg = styled.div`
width: 80%;
	max-height: 300px;
	box-sizing: border-box;
	border-radius: 10px;
	overflow: visible;
  overflow-wrap: break-word;
  color: white;
  font-size: 1.4em;
  line-height: 1.6;
  padding: 10px 10px;
  @media(min-width: 700px) {
    width: 500px;
  }
`;
export const Info = styled.div`
  width: 100%;
	box-sizing: border-box;
	border-radius: 10px;
	overflow: visible;
  overflow-wrap: break-word;
  background-color: #0191c8;
  color: #fff;
  font-size: 1.3em;
  line-height: 1.5;
  padding: 10px;
  @media(min-width: 750px) {
    width: 500px;
    align-self: center;
  }
`;

