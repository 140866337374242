/* PlayerHomeFeatured */

import React, {Component} from "react";
import "./player.css";


class PlayerHomeFeatured extends Component {

	render() {

		return (
			<div className="homePlayer">
				<div className="homePlayerTitle">
					<p> Featured Songs</p>
				</div>
				<div className="homePlayerSubTitle">
					<p>check out all my songs in the MUSIC page</p>
				</div>
			</div>

		);
	}
}

export default PlayerHomeFeatured;