/* EmVerify */
import React, {Component} from "react";
import {Title, Info} from "./theme.js";
//import "./gcss.css";
import FormEnd from "./FormEnd.js";
import "./forms.css";
//Was going to use queryString (below), but this.props.location.search works
//import queryString from"query-string";



const msgSuccess = "Thanks, you are subscribed to waynne.ca";
//const msgExpired = "Sorry, Confirmation must be done within 2 days - Please Subscribe again, or send us a CONTACT message and we will assist you";
const msgFail = "Sorry, there was a problem with the Verification - Please Subscribe again, or send us a CONTACT message and we will fix it";
const msgDuplicate ='You are already Confirmed - no problem, have a Great Day:)';
const msgTechProb = 'We are experiencing Technical Problems, Please try again later.';


class EmVerify extends Component {
	constructor(props) {
		super(props);
		this.state = {
			verifying: false,
			message: 'default'
		};
		console.log(this.props);
	}

	componentDidMount () {

		this.setState({ verifying: true});

		let proceed = true;

		// get the url params and validate

		const tokenIn = this.props.location.state.token;
		if (tokenIn.startsWith('?token')) {
			console.log('valid');
			//console.log(tokenIn);
		} else {
			proceed = false;
			console.log(proceed);
		}

		if (!proceed) {
			this.setState({
				message: msgFail,
				verifying: false
			});

		} else {
			//fetch do a get to determine if the email already exists in db as well as validate jwt

			// 'host' is set by react start/build

			const host = (process.env.REACT_APP_EXPRESS_URL);

			// host = the http(s) + the host + the port(localhost only (dev))

			const uri = host + '/routes/verifyEmail';

			const expressUrl = (uri + tokenIn);
			//console.log('url: ' + expressUrl);

			let ftchMsg = '';

			fetch(expressUrl, {
				method: 'GET',
				mode: 'cors'
			})

			//Note: the config of this .then is specifically for accessing
			//both res.status and res.json()
				.then(res => {
					res.json().then(data => {
						// code that can access both here
						console.log(res.status);
						console.log(data.error);
						switch (res.status) {

						case 200:
							ftchMsg = msgSuccess;
							break;
						case 400:
							if (data.error === '1062') {
								ftchMsg = msgDuplicate;
								console.log('1062 dup err');
							} else {
								ftchMsg = msgFail;
							}
							break;
						case 403:
							if (data.error === 'expired') {
								ftchMsg = msgFail;

								// Add other errors that can occur for jwt

							} else if (data.error === 'badJwt') {
								ftchMsg = msgFail;
							} else {
								ftchMsg = msgTechProb;
							}
							break;
						default:
							console.log('unhandled');
							ftchMsg = msgTechProb;
							throw new Error(ftchMsg);
						}
						this.setState({message: ftchMsg, verifying: false});
					})
						.catch(error => this.setState({error, isLoading: false}));
				});
		}
	}
	render () {
		//(this.state.proceed<i className="fas fa-fan fa-spin"></i>
		return (
			<div className="navbar-spacer flexCenter">
				<div>
					<Title>
						EMAIL VERIFICATION
					</Title>
				</div>
				<div>
					<FormEnd endMsg={this.state.message}/>
				</div>
				<div className="flexCenter">
					<Info>
						<p>Waynne will occasionally be in touch. You can cancel at any time by clicking the unsubscribe link at the bottom of any email you receive from us.</p>
						<p> WE DON'T SHARE YOUR INFORMATION WITH ANYONE - EVER!</p>
					</Info>
				</div>
			</div>
		);
	}
}
export default EmVerify;