/* About */
import React, { Component } from "react";
import { Title } from "./theme.js";
import "./about.css";
import FormMgrElist from "./FormMgrElist";
import waynnebeach from './images/waynne_beach2.JPG';


class About extends Component {
	render() {
		return (
			<div className="navbar-spacer">
				<div>
					<Title>ABOUT</Title>
				</div>
				<div>
					<img src={waynnebeach} alt='Waynne on the beach' className="imgAbout" />
				</div>
				<div className="wrapper article">
					<p>
              In school Waynne picked the guitar up and found it much more
              interesting than whatever else was being taught. Waynne calls his
              music West Coast meets East Coast - a combination of folk,
              country and a pinch of rock. His lyrics are tinged with the rough and tumble culture of rural Canada. He has lived in mining, fishing and logging towns throughout British Columbia, worked in the oil patch in the prairies, and now lives in a small New Brunswick town. As a songwriter he celebrates the wacky humour, grit, determination and authenticity of rural Canadians.
					</p>
					<p>
						The lyrics of Waynne's latest album "Free For All" are influenced by life, love and his experiences in the Oil Patch and rural New Brunswick.
					</p>
					<p>
              Waynne's first album titled "Waynne and Friends" is a collection
              of his original songs. The album was produced by Nando Sporanzo at
              the CMS Studio in Moncton, NB. He was supported by a number of great artists, including Ray Legere (violin and vocals), Guillame Basque (vocals), Joe Fahey(Guitar and vocals),
              Brian Arsenault(Bass), Mike Porelle(Drums), Claude Gallant(Guitar) and Kim Jamieson(Vocals).
					</p>
					<p>
              Waynne submitted "Waynne and Friends" to the Maritimes
              Broadcasting StarTrack contest, and was the winner of the
              Songwriter category. As well, he was awarded a Special Merits
              recognition from the New Brunswick Premier.
					</p>
					<p>
              He continues to develop his songwwriting craft, including participating in songwriters courses featuring successful songwriters such as Sylvia Tyson and Ron Hynes.
					</p>
					<p>
              Waynne has also recorded 2 songs ("Charleys Town" and "Gwendolyne"),  produced by Bob Gallo in Phase One Studio in
              Toronto.
					</p>
				</div>
				<div>
				<FormMgrElist />
				</div>
				<div className="footer">
				</div>
			</div>
		);
	}
}
export default About;
